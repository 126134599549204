@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.MentionedInSectionBody {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MentionedInSectionContainer {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.MentionedInSectionHeader {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MentionedInSectionLogosBody {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E8E8E8;
  width: 100%;
}

.MentionedInSectionLogosContainer {
  display: flex;
  max-width: 840px;
  width: 60%;
  justify-content: center;
  align-items: center;
}

.MentionedInSectionLogo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MentionedInSectionLogoImage {
  width: 50%;
  height: 50%;
  object-fit: contain;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

/* Visible state for logo images */
.isVisible {
opacity: 1;
transform: translateY(0);
}

@media only screen 
and (max-width: 1000px)  {
  .MentionedInSectionLogosContainer {
    width: 65%;
  }
  .MentionedInSectionLogoImage {
    width: 70%;
    height: 100%;
  }
}

@media only screen 
and (max-width: 700px)  {
  .MentionedInSectionLogosContainer {
    width: 70%;
  }
  .MentionedInSectionLogoImage {
    width: 75%;
    height: 100%;
  }
}

@media only screen 
and (max-width: 560px)  {
  .MentionedInSectionContainer {
    margin-top: 100px;
    margin-bottom: 100px;
    height: 200px;
  }
  .MentionedInSectionLogosContainer {
    width: 80%;
  }
  .MentionedInSectionLogoImage {
    width: 90%;
    height: 100%;
  }
}