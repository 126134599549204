@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.AppShowcaseTextHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppShowcaseTextBody {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.AppShowcaseTextContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  font-size: 20px;
  flex-direction: column;
}

.AppShowcaseTextContainer p {
  width: 80%;
  margin: 10px;
  
}

.AppShowcaseTextLinks {
  font-weight: bold;
}

@media only screen
and (max-width: 870px)  {

  .AppShowcaseTextHeader {
    height:70px;
    width: 100%;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .AppShowcaseTextContainer {
    background-color: #E8E8E8;
  }
}