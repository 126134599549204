.backdropBlur {
    position: fixed;
    background-color: white;
    backdrop-filter: blur(10px); /* apply the blur */
    top: 0px;
    left: 0%;
    width: 100vw;
    height: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: ease-in-out .5s;
    overflow: hidden;
}

.backdropBlur:target {
    height: 100vh;
}

.backdropLink {
    color: black;
    font-size: 35px;
    font-weight: 700;
    text-decoration: none;
}


@media only screen and (min-width: 725px) {
    
    .backdropBlur {
        display: none;
    }

}