
.demoOptionCardsBody {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 2000px;
    max-height: 1700px;
}

.demoOptionCardsArrowsContainer {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
}

.demoOptionCardsArrowLeft {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 40px;
    color : white;
    z-index: 20;
}

.demoOptionCardsArrowSpacer {
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;
}

.demoOptionCardsArrowRight {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 40px;
    color : white;
    z-index: 20;
}

.demoOptionCardsArrowRight:active, .demoOptionCardsArrowRight:focus {
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

.demoOptionCardsArrowLeft:active, .demoOptionCardsArrowLeft:focus {
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}


.demoOptionCardsContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.demoOptionTextSliderContainer{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    height: 100%;
    width: 100%;
    opacity: 1;
    z-index: 10;
    transition: transform 0.5s ease, opacity 0.5s;
}

.demoOptionTextSlider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.demoOptionText {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    width: 100%;
    opacity: 0;
    transition: opacity 0.25s ease;
}



.showOptionText {
    opacity: 1 !important;
}

.optionAnimationSliding {
    position: relative;
    opacity: 1 !important;
    transition: transform 0.25s ease;
}

.demoOption {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex: 1;
    cursor: pointer;
    border-radius: 5px;
    max-width: 30%;
    height: 100%;
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s, max-width 0.3s ease;
}

.demoOption:active, .demoOption:focus {
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

.demoOptionImage {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.demoOptionOpacity {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

.demoOptionSelected {
    max-width: 33%;
}

.demoOptionSelected .demoOptionOpacity{
    opacity: 0;
}
 

.demoSlideOut {
    transform: translateY(-100%);
    opacity: 0;
}

.demoHide {
    display: none;
}

.optionAnimationRight {
    transform: translateX(111%);
}

.optionAnimationLeft {
    transform: translateX(-111%);
}


@media only screen and (max-width: 1000px) {
    .demoOptionCardsArrowsContainer {
        display: flex;
    }

    .demoOption {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    .demoOptionSelectedMobile {
        opacity: 1;
    }
    .demoOptionOpacity {
        display: none;
    }

    .demoOptionText {
        color: white;
        font-size: 37px;
        width: 65%;
    }

    .optionAnimationMobileRight {
        transform: translateX(100%);
    }

    .optionAnimationMobileLeft {
        transform: translateX(-100%);
    }

    .optionAnimationRight {
        transform: translateX(0);
    }

    .optionAnimationLeft {
        transform: translateX(0);
    }
}
