@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.HeaderTextButtonBody {
  height: auto;
  background-color: white;
}

.HeaderTextButtonText {
  font-size: 20px;
  color: #686868;
}

.HeaderTextButtonButton {
  background-color: #3398F4;
  border: none;
  border-radius: 25px;
  height: 50px;
  box-shadow: none;
  line-height: 20px;
  
}