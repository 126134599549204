.teste {
    scroll-behavior: smooth;
}

.demoPageContainer {
    display: flex;
    height: 100vh;
    flex-direction: row;
    background-color: #171717;
    justify-content: space-between;
}

.column {
    width: 100%;
}