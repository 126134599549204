@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.ContactFoundersSectionBody {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1F1F1;
}

.ContactFoundersSectionContainer {
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 80%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
}

.ContactFoundersSectionFounderContainer {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ContactFoundersSectionFounderContainer p {
  color: #2E2E2E;
}

.ContactFoundersSectionConnect {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ContactFoundersSectionHeader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactFoundersSectionSocialMedia {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.ContactFoundersSectionSocialMediaIconContainer{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactFoundersSectionSocialMediaIcon{
  object-fit: contain;
  width: 70%;
  max-height: 60px;
  cursor: pointer;
}



@media only screen 
and (max-width: 870px)  {
  .ContactUsSectionContainer {
    flex-direction: column;
    height: 800px;
  }
  .ContactUsSectionText {
    flex: 1;
  }
  .ContactUsSectionImageContainer {
    flex: 1;
  }
  .ContactUsSectionImage {
    width: 100%;
    height: 100%;
  }
  .ContactFoundersSectionFounderContainer {
    flex-direction: column;
  }
  .ContactFoundersSectionSocialMedia{
    width: 80%;
  }
}

@media only screen 
and (max-width: 560px)  {
  .ContactUsSectionContainer {
    flex-direction: column;
    height: 600px;
  }
   
}