

.hamburgerButton {
    display: none;
    z-index: 10;
    transition: background-color .5s;
}

.hamburgerButtonStripe1{
    position: absolute;
    height: 2px;
    border-radius: 2px;
    width: 30px;
    top: 8px;
    margin-left: 5px;
    background-color: black;
    transition: transform .3s, top .3s, margin .3s, background-color .5s;
}

.hamburgerButtonStripe2{
    position: absolute;
    height: 2px;
    border-radius: 2px;
    width: 30px;
    top: 18px;
    margin-left: 5px;
    background-color: black;
    transition: opacity .2s, background-color .5s;
}

.hamburgerButtonStripe3{
    position: absolute;
    height: 2px;
    border-radius: 2px;
    width: 30px;
    top: 28px;
    margin-left: 5px;
    background-color: black;
    transition: transform .3s, top .3s, margin .3s, background-color .5s;
}



.hamburgerButton0Opacity{
    opacity: 0;
    background-color: white !important;
}

.hamburgerButtonRotate45Left {
    position: absolute;
    top: 19px;
    width: 30px !important;
    transform: rotate(45deg);
    margin-left: 5px;
    background-color: black !important;
}

.hamburgerButtonRotate45Right {
    position: absolute;
    top: 20px;
    width: 30px !important;
    transform: rotate(-45deg);
    margin-left: 5px;
    background-color: black !important;
}

.hamburgerMenuOpen {
    background-color: rgba(0, 0, 0, 0) !important;
}


@media only screen and (max-width: 725px) {
    
    .hamburgerButton {
        display: inline-block;
        position: absolute;
        height: 40px;
        width: 40px;
        right: 20px;
        top: 9px;
    }

}