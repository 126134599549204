.demoAppInformationContainer {
    display: flex;
    height: 100vh;
    flex-direction: row;
    background-color: transparent;
    justify-content: space-between;
    scroll-snap-align: start;
    scroll-padding: -50vh;
}

.demoAppInformationColumn {
    width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    word-wrap: break-word;
}

.demoAppInformationRow {
    width: 80%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: sticky;
}


@media only screen and (max-width: 725px) {
    
    .demoAppInformationContainer {
        display: flex;
        height: 100vh;
        flex-direction: column-reverse;
        background-color: transparent;
        justify-content: space-between;
        scroll-snap-align: start;
        scroll-padding: -50vh;
    }
    .demoAppInformationColumn {
        height: 33%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        word-wrap: break-word;
        font-size: 15px;
    }
    
    .demoAppInformationRow {
        width: 80%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: sticky;
    }

}