.contactContainer {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 200px);
    min-height: 500px;
}

.contactTitle {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

form{
    padding:3vw;
    width: 90vw;
    max-width: 1250px;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.formInformationInput {
    width: 100%;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}


label{
    margin-bottom:15px;
    position:relative;
    border-bottom:1px solid #ddd;
    width: 45%;
}

input{
    width:100%;
    padding:10px 0px;
    margin-top:20px;
    border:none;
    outline:none;
}
input::placeholder{
    opacity:0;
}
.contactContainer span{
    position:absolute;
    top:0;
    left:0;
    transform:translateY(30px);
    font-size:0.825em;
    transition-duration:150ms;
    color: rgb(26, 26, 26);
}
button{
    padding:15px 30px; 
    margin-top:20px;
    background:black;
    color:#fff;
    border:1px solid black;
    font-size: 22px;
    cursor:pointer;
    border-radius:3px;
    font-family: 'Aleo', serif;
    box-shadow: 2px 2px 2px black;
}

label:focus-within > span,
input:not(:placeholder-shown) + span{
    color:black;
    transform:translateY(0px);
}

@media screen and (max-width: 750px) {
    .Message {
        width: 95%;
    }
  }