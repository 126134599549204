@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.ContactUsSectionBody {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactUsSectionContainer {
    height: 500px;
    max-width: 1400px;
    display: flex;
}

.ContactUsSectionText {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.8s ease, opacity 0.8s ease;
}

.ContactUsSectionImageContainer {
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.8s ease, opacity 0.8s ease;
}

.ContactUsSectionImage {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.slideInFromLeft {
  transform: translateX(0);
  opacity: 1;
}

.slideInFromRight {
  transform: translateX(0);
  opacity: 1;
}


@media only screen 
and (max-width: 870px)  {
    .ContactUsSectionContainer {
        flex-direction: column;
        height: 800px;
    }
    .ContactUsSectionText {
        flex: 1;
    }
    .ContactUsSectionImageContainer {
        flex: 1;
    }
    .ContactUsSectionImage {
        width: 100%;
        height: 100%;
    }
}

@media only screen 
and (max-width: 560px)  {
    .ContactUsSectionContainer {
        flex-direction: column;
        height: 600px;
    }
   
}