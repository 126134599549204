@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.AboutUsSectionBody {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AboutUsSectionContainer {
    height: auto;
    max-width: 1400px;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}


.AboutUsSectionTextContainer {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
}

.AboutUsSectionImageContainer {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AboutUsSectionImage {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.AboutUsSectionLogo{
    width: 70%;
    object-fit: contain;
}

@media only screen 
and (max-width: 870px)  {
    .AboutUsSectionContainer {
        flex-direction: column-reverse;
        height: auto;
    }
    .AboutUsSectionText {
        flex: 1;
    }
    .AboutUsSectionImageContainer {
        flex: 1;
    }
    .AboutUsSectionImage {
        width: 60%;
        height: 100%;
    }

    .AboutUsSectionLogo{
        margin-bottom: 40px;
    }
}

