@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.screen {
    background-image: url('../../../public/stockholm.jpg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    width: 100vw;
    height: 100vh;
    scroll-snap-align: start;
}

.darkOpacity {
    background-color: black;
    opacity: 30%;
    width: 100vw;
    height: 100vh;
}

.contentContainer {
    backdrop-filter: blur(15px); /* apply the blur */
    top: 0%;
    left: 0%;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.startPageImage {
    width: 230px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
}

.startPageImageContainer {
    width: 240px;
    height: 65px;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 10px;
    margin-bottom: 10px;
    background-color: white;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 80vw;
    text-align: center;
    margin-top: 100px;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
}

.logo {
    width: 70px;
    height: auto;
}

.line {
    height: 0.5px;
    width: 400px;
    background-color: white;
}

.header {
    color: white;
    font-size: 55px;
    font-weight: 700;
}

.description {
    color: white;
    font-size: 30px;
    font-weight: 400;
    text-decoration: none;
}

@media only screen 
and (max-width: 700px)  {
    .line {
        width: 300px;
    }
 }

@media only screen 
and (max-width: 512px)  {
    .logo {
        width: 50px;
    }
    .container {
        height: 100px;
    }

    .header {
        font-size: 50px;
    }

    .description {
        font-size:20px;
    }
 }

 @media only screen 
and (max-width: 400px)  {
    .header {
        font-size: 40px;
    }
   .line {
    width: 200px;
   }
 }