@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.SeenByBarBody {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SeenByBarContainer {
    height: 300px;
    display: flex;
    width: 100%;
    max-width: 1400px;
    flex-direction: column;
}

.SeenByBarHeader {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SeenByBarBarContainer {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SeenByBarText {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #4D4D4D;
}