.demoBody {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1f1f1f;
    overflow: hidden;
}

.demoContainer {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
}

.demoNavbarSpacer {
    height: 60px;
    width: 100%;
}

.demoCountryOptions {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.5s ease, opacity 0.5s;
}

.demoParkingSignOptions {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.5s ease, opacity 0.5s;
}

.demoPhoneContainer {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.5s ease, opacity 0.5s;
}

.demoContainerAnimation {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
}