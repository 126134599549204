.demoIntroPageContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    scroll-snap-align: start;
}

.demoIntroPageTitle {
    margin-top: 12vh;
    font-size: 35px;
    text-align: center;
    font-weight: 700;
}

@media only screen and (max-width: 725px) { 

    .demoIntroPageTitle {
        font-size: 1.5rem;
    }
}