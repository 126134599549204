.personContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 65px;
}

.personInformationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 25px;
}

.personImage {
    height: 220px;
    border-radius: 200px;
    margin-block-end: 0;
}

.personName {
    font-size: 20px;
    font-weight: 700;
    margin-block-end: 3px;
    margin-block-start: 3px;
}

.personText {
    font-size: 18px;
    margin-block-end: 3px;
    margin-block-start: 3px;
    font-style: italic;
}

.personEmail {
    font-size: 18px;
    text-decoration: none;
    color: black;
    margin-block-end: 3px;
    margin-block-start: 3px;
}

@media screen and (max-width: 1020px) {
    .personImage {
      height: 170px;
    }

    .personText {
        font-size: 16px;
    }

    .personName {
        font-size: 18px;
    }
  }
