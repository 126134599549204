
.containerScrollPositionsDot {
    display: flex;
    height: 14px;
    width: 14px;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    
}

.scrollPositionsDot {
    height: 4px;
    width: 4px;
    border-radius: 12px;
    cursor: pointer;
}

.containerScrollPositionsDot:hover .scrollPositionsDot {
    height: 8px;
    width: 8px;
    border-radius: 8px;
}



.scrollPositionsDotSelected {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    display: inline-block;
}

