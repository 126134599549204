@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.TryDemoInfoSectionBody {
    display: flex;
    justify-content: center;
    align-items: center;
}

.TryDemoInfoSectionContainer {
    height: 600px;
    max-width: 1400px;
    display: flex;
    justify-content: center;
}


.TryDemoInfoSectionText {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.8s ease, opacity 0.8s ease;
}

.TryDemoInfoSectionImageContainer {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.8s ease, opacity 0.8s ease;
}

.TryDemoInfoSectionImage {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.slideInFromLeft {
    transform: translateX(0);
    opacity: 1;
}

.slideInFromRight {
    transform: translateX(0);
    opacity: 1;
}

@media only screen 
and (max-width: 870px)  {
    .TryDemoInfoSectionContainer {
        flex-direction: column;
        height: 800px;
    }
    .TryDemoInfoSectionText {
        flex: 1;
    }
    .TryDemoInfoSectionImageContainer {
        flex: 1;
    }
    .TryDemoInfoSectionImage {
        width: 100%;
        height: 100%;
    }
}

@media only screen 
and (max-width: 560px)  {
    .TryDemoInfoSectionContainer {
        flex-direction: column;
        height: 600px;
    }
   
}


