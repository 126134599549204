
#qrDemoContentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.qrDemoContentTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    flex:1;
}

.qrDemoContentButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.qrDemoContentButton{
    width: 60%;
    height: 50px;
    background-color: #000000;
    border-radius: 50px;
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
    margin-bottom: 7px;
}

.qrDemoContentTextRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.qrDemoContentTextTitle{
    flex: 1;
    padding-left: 25%;
    font-size: 17px;
}

.qrDemoContentTextContent{
    flex: 2;
    font-size: 17px;
}