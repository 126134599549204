.gradient {
    /* transition properties */
    transition: background-color 1s;
    -moz-transition: background-color 1s;
    -webkit-transition: background-color 1s;
    -o-transition: background-color 1s;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}