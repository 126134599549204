
.phoneDemoFlowContainer {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1400px;
    height: 100%;
    max-height: 800px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.phoneDemoFlowBackButton {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 10px;
    left: 100px;
    cursor: pointer;
}

.phoneDemoFlowBackButtonText {
    color: white;
    font-size: 30px;
    z-index: 20;
}

.phoneDemoFlowPhoneContainer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
}

.phoneDemoFlowPhone {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s;
}

.phoneDemoFlowPhoneAnimation1 {
    transform: translateY(0%);
    opacity: 1;
}

.phoneDemoFlowPhoneAnimation2 {
    transform: translateY(-100%);
    opacity: 0;
}

.phoneDemoFlowPhoneImage {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 80%;
    max-width: 100%;
    object-fit: contain;
}

.phoneDemoFlowPhoneNextButton{
    padding-top: 10px;
    color: white;
    font-size: 30px;
    cursor: pointer;
}


@media only screen and (max-width: 1000px) {
    .phoneDemoFlowPhoneContainer {
        width: 80%;
        height: 80%;
    }
    .phoneDemoFlowBackButton {
        display: none;
        left: 40px;
    }

    .phoneDemoFlowPhone {
        justify-content: flex-start;
    }
}