.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #171717;
    color: white;
    width: 100vw;
    min-height: 200px;
}

.footerColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 20vw;
}

.footerColumnTitle {
    margin-top: 7%;
    font-weight: 700;
    font-size: 20px;
}

.footerColumnLink {
    font-size: 16px;
    color: white;
    text-decoration: none;
    padding-bottom: 16px;
}

.footerColumnItem {
    font-size: 16px;
    color: white;
    text-decoration: none;
}

.footerColumnCenterTitle {
    font-weight: 700;
    font-size: 40px;
}

.footerImg {
    height: 24px;
    width: 24px;
}

@media screen and (max-width: 750px) {
    .footerColumnItem {
        font-size: 14px;
    }

    .footerColumnCenterTitle {
        font-size: 32px;
    }

    .footerColumnTitle {
        font-size: 18px;
    }

    .footerColumnLink {
        font-size: 14px;
    }
  }

  @media screen and (max-width: 530px) {
    .footerContainer {
        flex-wrap: wrap;
    }

    .center {
        display: none;
    }

    .footerColumn {
        width: 50vw;
    }

    .footerColumnItem {
        font-size: 15px;
    }

    .footerColumnCenterTitle {
        font-size: 34px;
    }

    .footerColumnTitle {
        font-size: 19px;
    }

    .footerColumnLink {
        font-size: 15px;
    }
  }