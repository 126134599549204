@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.ProgressBarBody {
  height: 30px;
  width: 100%;
  background: #E1E1E1;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.ProgressBarFiller {
  height: 100%;
  width: 0%;
  background-color: #3398F4;
  transition: width 1s ease-out;
  border-radius: 15px; 
}
