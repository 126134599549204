.phone-container {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 12vh;
    margin-top: -50vh;
}

.phone {
    position: absolute;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s, opacity 0.3s linear;
}

@media only screen and (max-width: 725px) {
    
    .phone {
        margin-top: -8vh;
        position: absolute;
        height: 50vh !important;
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s, opacity 0.3s linear;
    }

}
