

.NewFooterBody{
    background-color: #1F1F1F;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NewFooterContainer {
    display: flex;
    max-width: 1400px;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #DCDCDC;
    padding-top: 20px;
    padding-bottom: 20px;
}

.NewFooterLinks{
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: center;
    align-items: center;
}

.NewFooterLinks p{
    margin-left: 10px;
    margin-right: 10px;
}

@media only screen
and (max-width: 870px)  {
    .NewFooterLinks{
        flex-direction: column;
        width: 100%;
    }
    .NewFooterLinks p{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}