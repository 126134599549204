@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&display=swap');

body, html {
  font-family: 'Aleo', serif;
}

.AppShowcaseSectionBody {
  display: flex;
  background-color: #E8E8E8;
  justify-content: center;
  align-items: center;
}

.AppShowcaseSectionContainer {
    height: 700px;
    max-width: 1400px;
    display: flex;
    justify-content: center;
}


.AppShowcaseSectionText {
    flex: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.8s ease, opacity 0.8s ease;
}

.AppShowcaseSectionImageContainer {
    flex: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.8s ease, opacity 0.8s ease;
}

.AppShowcaseSectionImage {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.slideInFromLeft {
    transform: translateX(0);
    opacity: 1;
  }
  
  .slideInFromRight {
    transform: translateX(0);
    opacity: 1;
  }

@media only screen 
and (max-width: 870px)  {
    .AppShowcaseSectionContainer {
        flex-direction: column-reverse;
        height: 900px;
    }
    .AppShowcaseSectionText {
        flex: 1;
        height: 30%;
    }
    .AppShowcaseSectionImageContainer {
        flex: 1;
        height: 70%;
        align-items: flex-start;
    }
    
}