.aboutUsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutUsTextContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutUsTitle {
    margin-top: 20vh;
    padding-bottom: 5vh;
    text-align: center;
}

.aboutUsText {
    font-size: 20px;
    margin-top: 60px;
    width: 90vw;
    max-width: 1200px;
}

.aboutUsPersonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 70px;
    max-width: 1200px;
    width: 100vw;
}

@media screen and (max-width: 780px) {
    .aboutUsPersonContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }

@media screen and (max-width: 400px) {
    .aboutUsText {
        font-size: 18px;
        width: 95vw;
    }
    .aboutUsTitle {
        font-size: 25px;
    }
  }