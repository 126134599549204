
#qrDemoOval {
  position: absolute;
  top: -83px;
  left: -54px;
  width: 217%;
  height: 156px;
  background: linear-gradient(121.68deg, #000000 35.14%, #515050 65.28%);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 1;
}

#qrDemoContainer {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
}

#qrDemoDownloadLink {
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%;
    padding-bottom: 10px;


}

#qrDemoContent {
    position: absolute;
    top: 73px;
    left: 0px;
    width: 100%;
    height: calc(100% - 153px);
    z-index: 2;
}