.text_title {
    font-size: 30px;
    color: white;
    font-weight: 700;
}

.text1container {
    position: fixed;
    top:40vh;
    width: 26%;
    opacity: 0;
    transition:opacity 0.3s linear;

}


@media only screen and (max-width: 725px) {

    .text1container {
        position: fixed;
        top:55vh;
        width: 80%;
        opacity: 0;
        transition:opacity 0.3s linear;
    }
    .text_title {
        font-size: 1.5rem;
        color: white;
        font-weight: 700;
        text-align: center;
    }

}