
.scrollPositionIndicator {
    display: flex;
    position: fixed;
    z-index: 5;
    flex-direction: column;
    height: calc(100% - 100px);
    top: 50px;
    right: 20px;
    justify-content: center;
    
}