.privacyContainer {
    min-height: 100vh;
}

.privacyTextContainer {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.privacyText {
    width: 70vh;
    font-size: 24px;
}