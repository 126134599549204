.nav-container {
    display: flex;
    justify-content: space-between;
    position: fixed;
    padding-top: 12px;
    top: 0;
    height: 45px;
    width: 100vw;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

.link {
    text-decoration: none;
    font-size: 25px;
    display: inline-block;
    margin-right: 40px;
    transition: color .4s;
    -moz-transition: color .4s;
    -webkit-transition: color .4s;
    -o-transition: color .4s;
}

.home {
    font-weight: 700;
    text-decoration: none;
    font-size: 30px;
    display: inline-block;
    margin-left: 40px;
    transition: color .4s;
    -moz-transition: color .4s;
    -webkit-transition: color .4s;
    -o-transition: color .4s;
}

@media only screen and (max-width: 725px) {
    
    .navbarLinks {
        display: none;
    }

    .nav-container {
        background-image: none !important;
    }

    .navbarHamburgerMenue{
        
    }

}