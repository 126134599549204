
.startPageContact {
    height: calc(100vh - 250px);
    width: 100vw;
    scroll-snap-align: start;
}

.startPageContactSpacer {
    height: calc(100vh - 250px);
    padding-top: 50vh;
}

.startPageContactTextContainer {
    padding-top: calc(35vh);
    height: auto;
}

.startPageContactText {
    padding-left: 15%;
    color: white;
    font-size: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.startPageContactText span {
    font-weight: bold;
}