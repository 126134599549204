.redirectContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    width: 100vw;
}

.redirectTitle {
    font-size: 50px;
    font-weight: 700;
    color: black;
}

/* .redirectButton {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.redirectButtonText {
    color: white;
    text-decoration: none;
    height: 250px;
    width: 250px;
    background-color: purple;
}